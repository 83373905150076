import React from 'react'
import { connect } from 'react-redux'
import { Dropdown, Button, Modal, Form, Spinner } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import {
  toggleShowAddCondominia,
  resetCurrentCondominium,
  getAdministrators,
  getCondominium,
  createCondominium,
  editCondominium,
  createCondominiumContracts,
  updateErrors,
  getCondominia,
} from 'store/condominia'
import { getRoles } from 'store/users'
import validateFields from 'lib/validators/assignments'
import StyledSelect from 'components/system_wide/styled_select'
import SelectMunicipality from 'components/system_wide/select_municipality'
import ManageServiceModal from 'components/condominia/manage_service_modal'
import BulkImport from 'components/system_wide/bulk_import'
import { isAdmin as _isAdmin } from 'lib/utils'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'

const { t } = i18next

const initialState = {
  name: '',
  fiscal_code: '',
  iban: '',
  cap: '',
  region: '',
  province: '',
  city_id: '',
  country_id: 1,
  street: '',
  real_estate_units: false,
  services: [],
  edit_mode: false,
  mng_service_modal: false,
  show_bulk_modal: false,
}

const mstp = state => {
  let { services } = state.assignments.services
  let { role_id, id: user_id } = state.userInfo
  let { roles } = state.usersData
  let { errors, show_add_condominia, edit_condominia, administrators, loading } = state.condominia
  let { strings: trans } = state.translations
  let isAdmin = _isAdmin(role_id, roles)
  return {
    servicesOpts: services,
    show_add_condominia,
    errors,
    edit_condominia,
    administrators,
    isAdmin,
    user_id,
    trans,
    loading,
  }
}

class AddCondominium extends React.Component {
  static defaultProps = {
    mountOnMap: false,
  }
  state = {
    condominium: { ...initialState },
    condominium_hash: '',
    selected_administrator: '',
    validated: false,
    modifiedHash: false,
  }

  initialHash = null

  constructor(props) {
    super(props)
    if (!props.isAdmin) {
      this.state.selected_administrator = props.user_id
    }
  }
  async componentDidMount() {
    await this.props.dispatch(getRoles())
    await this.props.dispatch(getAdministrators())
  }

  updateState = prevProps => {
    let edit_mode = Object.keys(this.props.edit_condominia).length !== 1
    let region = null
    let province = null
    if (edit_mode === true) {
      region = this.props.edit_condominia.city.province.region.id
      province = this.props.edit_condominia.city.province.id
    }
    let condominium = {
      ...initialState,
      ...(edit_mode === true ? { ...this.props.edit_condominia, province, region } : {}),
    }
    this.setState(
      {
        condominium,
        selected_administrator:
          edit_mode === true ? this.props.edit_condominia.user_id : !prevProps.isAdmin ? prevProps.user_id : '',
        edit_mode,
      },
      () => {
        this.updateHash()
        this.initialHash = hash(this.state.condominium)
      }
    )
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.show_add_condominia !== this.props.show_add_condominia ||
      prevState.mng_service_modal !== this.state.mng_service_modal ||
      prevState.show_bulk_modal !== this.state.show_bulk_modal
    ) {
      this.updateState(prevProps)
      if (this.props.administrators.length === 0) this.props.dispatch(getAdministrators())
    }
    if (
      prevProps.show_add_condominia === true &&
      this.props.show_add_condominia === false &&
      this.state.mng_service_modal === false
    ) {
      this.setState({ ...initialState })
    }
  }

  onOpenModal = () => {
    this.props.dispatch(toggleShowAddCondominia())
    this.setState({ edit_mode: false })
  }
  onCloseModal = () => {
    this.props.dispatch(getCondominia())
    this.props.dispatch(toggleShowAddCondominia())
    if (this.props.mountOnMap === false) {
      this.props.dispatch(resetCurrentCondominium())
    }
  }
  updateHash = () => {
    let { condominium } = this.state
    this.setState({ condominium_hash: hash(condominium) })
  }
  isFormValid = () => {
    let { name, fiscal_code, cap, region, province, city_id, street } = this.state.condominium
    let { selected_administrator } = this.state
    return (
      name !== '' &&
      fiscal_code !== '' &&
      cap !== '' &&
      region !== '' &&
      province !== '' &&
      city_id !== '' &&
      street !== '' &&
      selected_administrator !== ''
    )
  }

  handleMunicipalityChange = values => {
    let condominium = { ...this.state.condominium, ...values }
    let errors = { ...this.props.errors }
    Object.keys(values).forEach(k => delete errors[k])
    if (this.state.validated) this.props.dispatch(updateErrors({ errors }))
    this.setState({ condominium }, () => this.updateHash())
  }

  handleAdministratorChange = selected => {
    this.setState({ selected_administrator: selected?.value || '' })
  }

  handleChange = ({ target: { name, value } }) => {
    let condominium = { ...this.state.condominium }
    let errors = { ...this.props.errors }
    delete errors[name]
    if (this.state.validated) this.props.dispatch(updateErrors({ errors }))
    condominium[name] = value
    this.setState({ condominium }, () => this.updateHash())
  }

  handleIsItChecked = e => {
    this.setState({ condominium: { ...this.state.condominium, [e.target.name]: e.target.checked } })
  }

  handleChangeServices = async contracts => {
    this.updateState(this.props)
    this.setState({ mng_service_modal: false })
    let condominium_id = this.props.edit_condominia.id
    if (condominium_id) {
      await this.props.dispatch(createCondominiumContracts({ condominium_id, contracts, single: true }))
      await this.props.dispatch(getCondominium({ condominium_id }))
    } else {
      this.setState({ condominium: { ...this.state.condominium, contracts } })
    }
  }

  onCreateEditCondominum = async (mng_service = false) => {
    let condominium = { ...this.state.condominium }
    let errors = validateFields({ ...condominium })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
      this.setState({ validated: true })
      return false
    } else {
      let services = condominium.services || []
      condominium.services = '{ ' + services.join(',') + ' }'
      condominium.user_id = this.state.selected_administrator
      if (this.state.edit_mode === true) {
        await this.props.dispatch(editCondominium({ condominium, mountOnMap: this.props.mountOnMap, mng_service }))
      } else {
        await this.props.dispatch(createCondominium({ user_id: condominium.user_id, condominium, mng_service }))
        if (mng_service === false) {
          await this.props.dispatch(
            createCondominiumContracts({ condominium_id: condominium.id, contracts: condominium.contracts })
          )
        }
      }
      this.setState({ validated: true })
      return true
    }
  }
  handleConfirm = async () => {
    await this.onCreateEditCondominum()
  }

  onOpenMngServices = async () => {
    if (this.initialHash !== this.state.condominium_hash) {
      showConfirmDialog(
        'Sono presenti delle modifiche',
        'Hai effettuato delle modifiche, vuoi salvare prima di accedere ai servizi associati?',
        async () => {
          let result = await this.onCreateEditCondominum(true)
          if (result) {
            this.setState({ edit_mode: true, mng_service_modal: true })
          }
        },
        () => {},
        'danger',
        'Salva e continua'
      )
    } else {
      this.setState({ edit_mode: true, mng_service_modal: true })
    }
  }

  onCloseMngServices = async () => {
    this.setState({ mng_service_modal: false })
  }

  showImportModal = () => this.setState({ show_bulk_modal: true })
  closeImportModal = () => this.setState({ show_bulk_modal: false })

  render() {
    let { condominium, selected_administrator, validated, edit_mode, mng_service_modal, show_bulk_modal } = this.state
    let { name, fiscal_code, iban, cap, region, province, city_id, street, real_estate_units } = condominium
    let {
      show_add_condominia,
      errors,
      servicesOpts,
      administrators,
      trans,
      mountOnMap,
      isAdmin,
      loading,
      edit_condominia,
    } = this.props
    let { services: c_services = [] } = edit_condominia
    let { services: tServices } = trans?.tables ?? {}

    let administratorsOpts = administrators.map(adm => ({
      value: adm.id,
      label: adm.business_name ?? `${adm.surname} ${adm.name}`,
    }))
    let build_administrator = administratorsOpts.find(adm => adm.value === selected_administrator?.toString()) || ''

    return (
      <>
        {mountOnMap === false && !isAdmin && (
          <Button
            onClick={!this.props.disabled && this.onOpenModal}
            disabled={this.props.disabled}
            style={{ minWidth: '15rem', maxHeight: '2.5rem' }}>
            <i className="fa fa-plus me-2"></i>
            <Trans i18nKey="condominia.add_condominia_modal.topbar_button">Aggiungi condominio</Trans>
          </Button>
        )}
        {mountOnMap === false && isAdmin && (
          <Dropdown className="me-2">
            <Dropdown.Toggle>
              {!this.props.disabled && (
                <>
                  <i className="fas fa-plus me-2" />
                  <Trans i18nKey="condominia.add_condominia_modal.topbar_button">Aggiungi condominio</Trans>
                </>
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={!this.props.disabled && this.onOpenModal}>
                <i className="fas fa-plus pe-2" />
                <Trans i18nKey="condominia.add_condominia_modal.single">Aggiungi Condominio</Trans>
              </Dropdown.Item>
              <Dropdown.Item onClick={this.showImportModal}>
                <i className="fas fa-upload pe-2" />
                <Trans i18nKey="condominia.add_condominia_modal.bulk">Importa Condomini</Trans>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Modal
          size="xl"
          backdrop="static"
          fullscreen="lg-down"
          centered
          show={show_add_condominia && !mng_service_modal}
          onHide={this.onCloseModal}
          className="menu-dialog">
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>
              <h4 className="text-primary p-1">
                {edit_mode === true ? (
                  <Trans i18nKey="condominia.edit_condominia.title">Modifica condominio</Trans>
                ) : (
                  <Trans i18nKey="condominia.add_condominia.title">Aggiungi condominio</Trans>
                )}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-row">
            <div className="col-12">
              <div className="flex-fill position-relative container">
                {isAdmin && (
                  <div className="row mb-3">
                    <div className="col">
                      <Form.Label className="text-muted">
                        <Trans i18nKey="condominia.search_administrator">Seleziona amministratore:</Trans>
                      </Form.Label>
                      <StyledSelect
                        isClearable
                        placeholder={t('condominia.select_administrator_placeholder', 'Seleziona amministratore..')}
                        value={build_administrator}
                        options={administratorsOpts}
                        onChange={this.handleAdministratorChange}
                        disabled={edit_mode === true}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-1">
                      <Form.Label>
                        <Trans i18nKey="condominia.condominia.form.name">Nome</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="name"
                        placeholder={t('assignments.condominia.form.name', 'Nome')}
                        value={name}
                        onChange={this.handleChange}
                        isInvalid={validated && 'name' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <Form.Group className="mt-2">
                      <Form.Label>
                        <Trans i18nKey="condominia.condominia.form.fiscal_code">Codice fiscale</Trans>
                      </Form.Label>
                      <span> *</span>
                      <Form.Control
                        name="fiscal_code"
                        placeholder={t('assignments.condominia.form.fiscal_code', 'Codice fiscale')}
                        value={fiscal_code}
                        onChange={this.handleChange}
                        isInvalid={validated && 'fiscal_code' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.fiscal_code}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-2">
                      <Form.Label>
                        <Trans i18nKey="condominia.condominia.form.iban">IBAN</Trans>
                      </Form.Label>
                      <Form.Control
                        name="iban"
                        placeholder={t('assignments.condominia.form.iban', 'IBAN')}
                        value={iban}
                        onChange={this.handleChange}
                        isInvalid={validated && 'iban' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.iban}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mt-4">
                  <SelectMunicipality
                    region={region}
                    province={province}
                    city={city_id}
                    cap={cap}
                    errors={errors}
                    onChange={this.handleMunicipalityChange}
                    showRequiredMarks={true}
                  />
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <Trans i18nKey="condominia.condominia.form.street_address">Indirizzo</Trans>
                        <span> *</span>
                      </Form.Label>
                      <Form.Control
                        name="street"
                        placeholder={t('assignments.condominia.form.street_address', 'Indirizzo')}
                        value={street}
                        onChange={this.handleChange}
                        isInvalid={validated && 'street' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="switch"
                        id="real_estate_units-switch"
                        label="Suddivisione unità immobiliari"
                        name="real_estate_units"
                        checked={real_estate_units}
                        onChange={this.handleIsItChecked}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Button onClick={this.onOpenMngServices} variant="secondary" disabled={!this.isFormValid()}>
                  <Trans i18nKey="condominia.mng_service.mng_service_modal">Associa servizi</Trans>
                </Button>
                {loading && (
                  <div
                    style={{
                      position: 'absolute',
                      justifyContent: 'center',
                      display: 'flex',
                      width: '96%',
                      minHeight: 50,
                      background: '#ffffff80',
                    }}>
                    <Spinner animation="border" role="status" variant="primary"></Spinner>
                  </div>
                )}
                <div className="d-flex align-content-between flex-wrap" style={{ minHeight: 50 }}>
                  {c_services.map(c => (
                    <div key={c.codename} className="flex-fill border-start p-3">
                      <i className={`text-muted ${c.icon_classes} me-2`} />
                      {tServices[c.codename] ?? c.description}
                    </div>
                  ))}
                  {c_services.length === 0 && <span className="text-muted p-3">Ancora nessun servizio associato</span>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCloseModal} variant="secondary" className="me-auto">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            {edit_mode === true ? (
              <Button onClick={this.handleConfirm} variant="primary" disabled={!this.isFormValid()}>
                <Trans i18nKey="condominia.add_modal.edit_button">Modifica</Trans>
              </Button>
            ) : (
              <Button onClick={this.handleConfirm} variant="primary" disabled={!this.isFormValid()}>
                <Trans i18nKey="condominia.add_modal.confirm_button">Aggiungi</Trans>
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        {mng_service_modal === true && (
          <ManageServiceModal
            servicesOpts={servicesOpts}
            selected_condominia={[condominium.id]}
            onToggleMngServices={this.onCloseMngServices}
            onChangeServices={this.handleChangeServices}
          />
        )}
        {show_bulk_modal === true && (
          <BulkImport
            isAdmin={isAdmin}
            administrators={this.props.administrators}
            current_user={this.props.user_id}
            trans={trans}
            model={'Condominium'}
            className="w-100"
            title={t('condominia.import_bulk_title', 'Importa condomini')}
            importUrl={`/v1/users/${this.props.user_id}/importers`}
            onDismiss={this.closeImportModal}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(AddCondominium)
